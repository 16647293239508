


















































import { Component, Vue } from 'vue-property-decorator';
import Navbar from "@/components/Navbar.vue"; // @ is an alias to /src

@Component({
  components: {
    Navbar
  },
})
export default class ConfirmShaming extends Vue {}

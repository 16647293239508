
































import {Component, Vue} from 'vue-property-decorator';
import Navbar from "@/components/Navbar.vue";
import ProductItem from "@/components/ProductItemComponent.vue";

@Component({
  components: {
    Navbar,
    ProductItem
  },
})
export default class SneakIntoBasket extends Vue {

}

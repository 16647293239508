

































import {Component, Vue} from 'vue-property-decorator';
import Navbar from "@/components/Navbar.vue";
import ProductItem from "@/components/ProductItemComponent.vue";

@Component({
  components: {
    Navbar,
    ProductItem
  },
})
export default class PriceComparison extends Vue {
  productItems = [
    {
      name: "OK Computer",
      description: "OK Computer is the third studio album by English rock band Radiohead, released on 21 May 1997 on EMI subsidiaries Parlophone and Capitol Records. The members of Radiohead self-produced the album with Nigel Godrich, an arrangement they have used for their subsequent albums.",
      price: "199ден / парче",
      img: "radiohead.jpg"
    },
    {
      "name": "Californication",
      "description": "Californication is the seventh studio album by American rock band Red Hot Chili Peppers. It was released on June 8, 1999, on Warner Bros. Records and was produced by Rick Rubin",
      "price": "299ден / парче",
      img: "rhcp.jpg"
    },
    {
      "name": "Po Tebe",
      "description": "Po tebe (in Macedonian Cyrillic: По тебе) is the fifth studio album by the Macedonian singer Toše Proeski. The album was released in Macedonia and subsequently in Serbia, Montenegro, Bosnia and Herzegovina, Croatia and Slovenia under the Serbian title Pratim te",
      "price": "5ден / минута",
      img: "tose.jpg"
    },
    {
      "name": "Sgt Pepper's Lonely Hearts Club Band",
      "description": "Sgt. Pepper's Lonely Hearts Club Band is the eighth studio album by the English rock band the Beatles. Released on 26 May 1967, it spent 27 weeks at number one on the Record Retailer chart in the United Kingdom and 15 weeks at number one on the Billboard Top LPs chart in the United States.",
      "price": "9ден / песна",
      img: "beatles.jpg"
    }
  ]
}
